import { Col, Row, Button, Image } from 'antd';
import styled from 'styled-components';

export const DownloadButton = styled(Button)`
	width: 11rem;
	margin: 0 0 1.5rem 0rem;
`;

export const ConsolidatedButton = styled(Button)`
	color: #2cc852;
	border: 1px solid #2cc852;
	width: 11rem;
	height: 40px;
	margin: 0 0 1.5rem 0rem;

	span:last-child {
		font-size: 15px;
	}
`;

export const DesconsolidatedButton = styled(Button)`
	color: #828282;
	border: 1px solid #828282;
	width: 11rem;
	height: 40px;
	margin: 0 0 1.5rem 0rem;
`;

export const ReportContainer = styled(Col)`
	background-color: white;
	margin-bottom: 20px;
	border-radius: 0.5rem;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const SectionTitle = styled.h2`
	font-weight: bold;
	margin-left: 40px;
	margin-bottom: 30px;
`;

export const SectionContainer = styled(Col)`
	margin-left: 60px;
	margin-right: 20px;
	padding-bottom: 20px;
`;

// HEADER
export const HeaderContainer = styled(Row)`
	padding-top: 40px;
	padding-bottom: 1rem;
	margin-inline: 10px;

	@media (max-width: 1400px) {
		img {
			width: 100px;
		}
		h1,
		h2 {
			font-size: 15px;
		}
	}

	@media (max-width: 1200px) {
		.kinebot-logo-report {
			width: 70px;
		}
	}
`;

export const ImageStyled = styled(Image)`
	padding-inline: 30px;
	max-height: 70px;
	border-radius: 50%;
	min-height: 100px;
	@media (max-width: 1400px) {
		padding-inline: 16px;
		border-radius: 50%;
		min-height: 70px;
	}
`;

export const HeaderTitle = styled.h1`
	text-align: start;
	font-weight: 500;
	font-size: 20px;
	margin: 0 0 0 2rem;
`;
export const HeaderSubTitle = styled.h2`
	text-align: start;
	font-weight: bold;
	font-size: 20px;
	margin: 0 0 0 2rem;
`;

export const FooterStyled = styled(Col)`
	display: flex;
	align-items: end;
	padding: 0 2.5rem;
	margin-bottom: 2.5rem;

	label {
		font-weight: bold;
		font-size: 1.2rem;
	}

	.ant-form-item {
		width: 100%;
	}

	textArea {
		width: 100%;
	}
`;

export const StyledRow = styled(Row)`
	margin-bottom: 0.5rem;
`;

export const Topic = styled.span`
	font-size: 0.8rem;
	font-weight: 600;
	margin-right: 0.5rem;

	@media (min-width: 1600px) {
		font-size: 1rem;
	}
`;

export const Value = styled.span`
	font-size: 0.8rem;
	font-weight: 400;

	@media (min-width: 1600px) {
		font-size: 1rem;
	}
`;
